import {
  Box,
  FormField,
  Cell,
  Page,
  Button,
  Layout,
  WixDesignSystemProvider,
  BrowserPreviewWidget,
  NumberInput,
  ColorInput,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Loader,
  Tooltip,
  Notification,
  IconButton,
} from "@wix/design-system";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import * as Icons from "@wix/wix-ui-icons-common";
import "@wix/design-system/styles.global.css";

const CustomScrollbar = styled.div`
  width: 100%;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: ${(props: any) => props.scrollbarThumbColor}
    ${(props: any) => props.scrollbarTrackColor};

  &::-webkit-scrollbar {
    width: ${(props: any) => props.width}px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props: any) => props.scrollbarThumbColor};
    border-radius: ${(props: any) => props.thumbRadius}px;
    border: ${(props: any) => props.border.size}px solid
      ${(props: any) => props.border.color};
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props: any) => props.scrollbarTrackColor};
    border-radius: ${(props: any) => props.trackRadius}px;
  }
`;

const ScrollBar = () => {
  const [isClickedUpgrade, setIsClickedUpgrade] = React.useState(false);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isOpening, setIsOpening] = React.useState(false);
  const [isModified, setIsModified] = React.useState(false);

  const [scrollbarThumbColor, setScrollbarThumbColor] = useState("#333333");
  const [scrollbarTrackColor, setScrollbarTrackColor] = useState("#f0f0f0");
  const [thumbRadius, setThumbRadius] = useState(5);
  const [trackRadius, setTrackRadius] = useState(5);
  const [scrollbarWidth, setScrollbarWidth] = useState(10);
  const [border, setBorder] = useState({
    color: "#333333",
    size: 4,
  });

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
  }, []);

  useEffect(() => {
    setIsModified(true);
  }, [
    scrollbarThumbColor,
    scrollbarTrackColor,
    thumbRadius,
    trackRadius,
    scrollbarWidth,
    border,
  ]);

  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=e3b1ab7e-103a-41a0-84ae-8feb7ee6226e&redirectUrl=https://certifiedcode.wixsite.com/scrollbar/_functions/@certifiedcode/base-backend/auth`;
  }

  // if (!instance) {
  //   return null
  // }

  const BASE_URL = `https://certifiedcode.wixsite.com/scrollbar/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
        if (
          !data.instance.permissions.includes("WIX_DEVELOPERS.GET_SITE_OWNER")
        ) {
          window.open(
            `https://go.certifiedcode.global/get/e3b1ab7e-103a-41a0-84ae-8feb7ee6226e/${data.site.siteId}`
          , "_blank");
        }
      })
      .catch(() => {});
  }

  function setSettings() {
    if (isOpening) {
      return;
    }
    setIsOpening(true);

    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        css: `
      ::-webkit-scrollbar {
        width: ${scrollbarWidth}px;
      }
  
      ::-webkit-scrollbar-thumb {
        background-color: ${scrollbarThumbColor};
        border-radius: ${thumbRadius}px;
        border: ${border.size}px solid ${border.color};
      }
  
      ::-webkit-scrollbar-track {
        background-color: ${scrollbarTrackColor};
        border-radius: ${trackRadius}px;
      }
    `,
        props: {
          scrollbarThumbColor,
          scrollbarTrackColor,
          thumbRadius,
          trackRadius,
          scrollbarWidth,
          border,
        },
      }),
    }).then(() => {
      setIsOpening(false);
      setOpen(!open);
      setIsModified(false);
    });
  }

  function getSettings() {
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsOpening(false);
        if (data?.props) {
          setScrollbarThumbColor(data.props.scrollbarThumbColor);
          setScrollbarTrackColor(data.props.scrollbarTrackColor);
          setThumbRadius(data.props.thumbRadius);
          setTrackRadius(data.props.trackRadius);
          setScrollbarWidth(data.props.scrollbarWidth);
          setBorder(data.props.border);
        }
      });
  }

  const handleThumbColorChange = (event: any) => {
    setScrollbarThumbColor(event);
  };

  const handleTrackColorChange = (event: any) => {
    setScrollbarTrackColor(event);
  };

  const handleThumbRadius = (e: any) => {
    setThumbRadius(e);
  };
  const handleTrackRadius = (e: any) => {
    setTrackRadius(e);
  };
  const handleWidthChange = (e: any) => {
    setScrollbarWidth(e);
  };

  // const applyStyleToSite = () => {
  //   const customScrollbarStyles = `
  //     ::-webkit-scrollbar {
  //       width: ${scrollbarWidth}px;
  //     }

  //     ::-webkit-scrollbar-thumb {
  //       background-color: ${scrollbarThumbColor};
  //       border-radius: ${thumbRadius}px;
  //       border: ${border.size}px solid ${border.color};
  //     }

  //     ::-webkit-scrollbar-track {
  //       background-color: ${scrollbarTrackColor};
  //       border-radius: ${trackRadius}px;
  //     }
  //   `;

  //   localStorage.setItem("customScrollbarStyles", customScrollbarStyles);

  //   applyCustomScrollbarStyles(customScrollbarStyles);

  //   alert("Scrollbar style applied to the site.");
  // };

  const applyCustomScrollbarStyles = (styles: any) => {
    // Create a new style element
    const styleElement = document.createElement("style");
    styleElement.innerHTML = styles;

    document.head.appendChild(styleElement);
  };

  const storedStyles = localStorage.getItem("customScrollbarStyles");
  if (storedStyles) {
    applyCustomScrollbarStyles(storedStyles);
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification show={isClickedUpgrade}>
        <Notification.TextLabel>
          Upgraded to Premium? Refresh the page to publish your changes.
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            setInstance();
            setIsClickedUpgrade(false);
          }}
        >
          Refresh
        </Notification.ActionButton>
        <Notification.CloseButton />
      </Notification>
      <Page>
        <Page.Header
          title="Scrollbar: Design & Customize"
          subtitle="You can change the scrollbar style of your site here."
          actionsBar={
            <Box direction="horizontal" gap="SP3">
              <Tooltip content="Watch setup tutorial on YouTube">
                <IconButton
                  skin="light"
                  as="a"
                  href="https://www.youtube.com/watch?v=JYZFcl7xJgU"
                  target="_blank"
                >
                  <Icons.Help></Icons.Help>
                </IconButton>
              </Tooltip>
              {/* <Button skin="inverted" prefixIcon={<Icons.Preview />}>Preview</Button> */}
              <Tooltip content="Save your changes to apply them to your site.">
                <Button
                  disabled={!isUpgraded}
                  onClick={setSettings}
                  prefixIcon={isOpening ? undefined : <Icons.Confirm />}
                >
                  {isOpening ? (
                    <Loader size="tiny" />
                  ) : isModified ? (
                    "Save"
                  ) : (
                    "Saved"
                  )}
                </Button>
              </Tooltip>
              <Button
                onClick={() => {
                  setIsClickedUpgrade(true);
                }}
                as="a"
                target="_blank"
                href={`https://www.wix.com/apps/upgrade/${"e3b1ab7e-103a-41a0-84ae-8feb7ee6226e"}?appInstanceId=${
                  instanceData?.instance?.instanceId
                }`}
                prefixIcon={
                  isUpgraded ? <Icons.PremiumFilled /> : <Icons.Premium />
                }
                skin={isUpgraded ? "premium-light" : "premium"}
              >
                {isUpgraded ? "Manage Subscription" : "Upgrade to Set Live"}
              </Button>
            </Box>
          }
        />
        <Page.Content>
          <Layout>
            <Cell span={5}>
              <Box direction="vertical" gap="SP1">
                <FormField label="Thumb Radius (px)">
                  <NumberInput
                    type="number"
                    id="borderRadius"
                    value={thumbRadius}
                    placeholder="e.g. 5"
                    onChange={handleThumbRadius}
                  />
                </FormField>
                <FormField label="Width (px)">
                  <NumberInput
                    type="number"
                    id="width"
                    value={scrollbarWidth}
                    placeholder="e.g. 10"
                    onChange={handleWidthChange}
                  />
                </FormField>
                <FormField label="Thumb Border Size (px)">
                  <NumberInput
                    type="number"
                    value={border.size}
                    placeholder="e.g. 10"
                    onChange={(e: any) =>
                      setBorder((prev: any) => ({ ...prev, size: e }))
                    }
                  />
                </FormField>
                <FormField label="Track Radius (px)">
                  <NumberInput
                    type="number"
                    id="trackRadius"
                    value={trackRadius}
                    onChange={handleTrackRadius}
                  />
                </FormField>
                <FormField label="Thumb Color">
                  <ColorInput
                    // type="color"
                    // id="backgroundColor"
                    value={scrollbarThumbColor}
                    onChange={handleThumbColorChange}
                  />
                </FormField>
                <FormField label="Thumb Border Color">
                  <ColorInput
                    // type="color"
                    // id="backgroundColor"
                    value={border.color}
                    onChange={(e: any) =>
                      setBorder((prev: any) => ({
                        ...prev,
                        color: e,
                      }))
                    }
                  />
                </FormField>
                <FormField label="Track Color">
                  <ColorInput
                    // type="color"
                    // id="backgroundColor"
                    value={scrollbarTrackColor}
                    onChange={handleTrackColorChange}
                  />
                </FormField>
              </Box>
            </Cell>
            <Cell span={7}>
              <BrowserPreviewWidget skin="gradient">
                <Box height={400} backgroundColor="#FFFFFF">
                  <CustomScrollbar
                    // @ts-ignore
                    scrollbarThumbColor={scrollbarThumbColor}
                    scrollbarTrackColor={scrollbarTrackColor}
                    thumbRadius={thumbRadius}
                    trackRadius={trackRadius}
                    width={scrollbarWidth}
                    border={border}
                  >
                    <MarketingPageLayout
                      horizontalSize="medium"
                      verticalSize="medium"
                      content={
                        <MarketingPageLayoutContent
                          size="medium"
                          title="What is Lorem Ipsum?"
                          content={`Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.`}
                          actions={<Button size="medium">Sample Button</Button>}
                        />
                      }
                    />
                  </CustomScrollbar>
                </Box>
              </BrowserPreviewWidget>
            </Cell>
            {/* <Cell>
              <Input type="button" onInputClicked={applyStyleToSite} value="Apply" />
            </Cell> */}
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
};

export default ScrollBar;
